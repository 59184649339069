import { createFeatureFlag } from '../model';

const $$crowdinInContextFeatureFlag = createFeatureFlag(
  'FE_CHECKOUT_CROWDIN_IN_CONTEXT'
);

const $$isPLLangEnabledFeatureFlag = createFeatureFlag(
  'KUPAY_1936_FE_CHECKOUT_PL_LANGUAGE_ENABLED'
);

const $$isESLangEnabledFeatureFlag = createFeatureFlag(
  'KUPAY_1936_FE_CHECKOUT_ES_LANGUAGE_ENABLED'
);

const $$recaptchaFeatureFlag = createFeatureFlag('RECAPTCHA_ENABLED');

const $$rateYourExperienceFeedbackFeatureFlag = createFeatureFlag(
  'KUPAY_2293_FE_RATE_YOUR_EXPERIENCE_FEEDBACK'
);

const $$sendReceiptWithLanguage = createFeatureFlag(
  'KUPAY_2084_FE_CHECKOUT_SEND_RECEIPT_WITH_LANGUAGE'
);

export {
  $$crowdinInContextFeatureFlag,
  $$isESLangEnabledFeatureFlag,
  $$isPLLangEnabledFeatureFlag,
  $$rateYourExperienceFeedbackFeatureFlag,
  $$recaptchaFeatureFlag,
  $$sendReceiptWithLanguage,
};
